import '../css/index.css';
import '../css/about.css';
import Bar from "../elements/bar";
import Footer from "../elements/footer";
import Map from "../elements/map";
import React from "react";


function About() {
    return (
        <div className="App">

            <Bar/>

            <div className={"About"}>
              <div className={"about_text"}>
                  <h1>Добро пожаловать в "КТМ"</h1>
                  <h3>KTM Group - ваш партнер в области высококачественной бытовой и коммерческой техники! С момента
                      нашего основания в 2006 году мы посвятили себя предоставлению надежных и эффективных решений для
                      различных областей вашей повседневной жизни.
                  </h3>
                  <br/><br/>
                  <h3>

                      С 2008 года мы стали важным участником рынка прачечного и ресторанного оборудования, уделяя особое внимание качеству и функциональности наших продуктов. Наш опыт и компетенции позволяют нам предлагать не просто технику, а интегрированные решения, соответствующие самым высоким стандартам.

                  </h3>
                  <h3>

                      Мы активно работаем в сферах тепло-холод, бытовая и коммерческая техника, стремясь обеспечить оптимальные условия для вашей повседневной деятельности. Наша философия основана на принципах честности, ответственности и постоянного совершенствования.



                  </h3>
                  <h3>

                      "КТМ" - это не просто компания, предоставляющая продукцию, мы создаем партнерские отношения. Мы гордимся тем, что наши клиенты могут полагаться на нас, благодаря высокому уровню обслуживания и поддержки. Мы стремимся быть не просто вашими поставщиками, но и надежными советчиками в выборе оптимальных решений.

                  </h3>
                  <h3>

                      Выбирая "КТМ", вы выбираете надежность, качество и профессионализм. Спасибо, что доверяете нам быть частью вашего успеха!
                  </h3>

              </div>

            </div>
            <Footer/>
            <Map/>


        </div>
    );
}

export default About;
