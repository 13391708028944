import '../css/index.css';
import '../css/about.css';
import Bar from "../elements/bar";
import Footer from "../elements/footer";
import Map from "../elements/map";
import React, {useState} from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import {fs} from "../firebase";

function Services() {



    const { t } = useTranslation();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');



    return (
        <div className="App">

            <Bar/>

            <div className={"Services"}>
                <div className="container">
                    <div className={"YES_icon"} id={"yes_icon"}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="icon" width="24" height="24"
                             viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                                strokeWidth="0" fill="currentColor"/>
                        </svg>
                    </div>
                    <div className={'verify'} id={'login'}>
                        {/* Login form */}
                        <input
                            className={'vi'}
                            placeholder={t('სახელი')}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                        <input
                            className={'vi'}
                            placeholder={t('mail')}
                            type="email"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <textarea
                            className={'ti'}
                            placeholder={t('შეტყობინება')}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className={'vb3'}>
                            {t('გაგზავნა')}
                        </button>

                    </div>

                </div>
            </div>
            <Footer/>
            <Map/>


        </div>
    );
}

export default Services;
