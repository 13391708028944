import '../css/index.css';
import '../css/about.css';
import Bar from "../elements/bar";
import Footer from "../elements/footer";
import Map from "../elements/map";

import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

import '../css/footer.css';


import 'mapbox-gl/dist/mapbox-gl.css';
import 'leaflet/dist/leaflet.css';

import '../css/index.css';
import '../css/map.css';

import customIconImage from '../img/map_icon.svg'; // Указать правильный путь к вашей иконке
import customIconImage2 from '../img/map_icon2.svg'; // Указать правильный путь к вашей иконке

function Map_page() {

    let year = new Date().getFullYear()
    const mapContainerRef = useRef(null);

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiZGlub3dheTIwMjMiLCJhIjoiY2xzMGtxMHNyMDFzaDJycXA1eHd4aHFlciJ9.MQNTRD7JBr9SEafwksOHUg';
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/dinoway2023/cls9m6b4o01ie01r4emhrh9mu/draft',
            center: [41.628319, 41.639922], // координаты центра карты
            zoom: 14, // начальное увеличение карты
        });

        // Создаем собственные иконки для каждого маркера
        const customIcon1 = document.createElement('div');
        customIcon1.className = 'custom-marker';
        customIcon1.style.backgroundImage = `url(${customIconImage})`;
        customIcon1.style.width = '140px'; // Укажите нужный размер
        customIcon1.style.height = '70px'; // Укажите нужный размер

        const customIcon2 = document.createElement('div');
        customIcon2.className = 'custom-marker';
        customIcon2.style.backgroundImage = `url(${customIconImage2})`;
        customIcon2.style.width = '140px'; // Укажите нужный размер
        customIcon2.style.height = '70px'; // Укажите нужный размер

        // Добавление первой точки с собственной иконкой
        new mapboxgl.Marker(customIcon1).setLngLat([41.630598, 41.639087]).addTo(map);

        // Добавление второй точки с собственной иконкой
        new mapboxgl.Marker(customIcon2).setLngLat([41.628319, 41.639922]).addTo(map);

        // Очистка ресурсов при размонтировании компонента
        return () => map.remove();
    }, []);

    return (
        <div className="App">

            <Bar/>

            <div ref={mapContainerRef} className={"Map_Page"} />


            <Footer/>
        </div>
    );
}

export default Map_page;
