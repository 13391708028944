
import firebase from "firebase/compat/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
import 'firebase/compat/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/analytics'
// Add Firebase storage
import 'firebase/compat/storage';


const firebaseConfig = {

    apiKey: "AIzaSyDF78hONjh0pTJHxPtQxE_eqV9sBaIYHew",
    authDomain: "ktm-web-e0c5f.firebaseapp.com",
    projectId: "ktm-web-e0c5f",
    storageBucket: "ktm-web-e0c5f.appspot.com",
    messagingSenderId: "525487577355",
    appId: "1:525487577355:web:6a78b709c9d92fb1f02a0e",
    measurementId: "G-BLS2P7572Y"

};

export const app = firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseConfig);

const auth = getAuth(app);
const fs = app.firestore();

const storage = app.storage();

export {fs,storage}