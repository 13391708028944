import '../css/index.css';
import '../css/add.css';
import React, { useEffect, useState } from "react";
import { fs,storage } from "../firebase";
import _isEqual from 'lodash/isEqual';



function AddProducts() {
    const [category, setCategory] = useState('');
    const [documents, setDocuments] = useState([]);
    const [documentFields, setDocumentFields] = useState({});
    const [selectedField, setSelectedField] = useState('');
    const [inputValues, setInputValues] = useState({});
    const [selectedDocument, setSelectedDocument] = useState('');
    const [displayedInputs, setDisplayedInputs] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [images, setImages] = useState(null);
    const imgTypes = [
        'image/jpeg',    // JPEG
        'image/jpg',     // JPG
        'image/png',     // PNG
        'image/gif',     // GIF
        'image/bmp',     // BMP
        'image/webp',    // WEBP
        'image/tiff',    // TIFF
        'image/apng',    // APNG
    ];

    const [loading, setLoading] = useState(false);
    const selectedFilesArray = Array.from(selectedFiles);

    const checkExistingProduct = async (newModel) => {
        try {
            const categoryRef = fs.collection(category.slice(1).toLowerCase());
            const querySnapshot = await categoryRef.get();

            for (const doc of querySnapshot.docs) {
                const data = doc.data();

                // Check if the document has the same "02მოდელი" value
                if (data["02მოდელი"] === newModel) {
                    console.log("Такой продукт уже есть");
                    alert("Такой продукт уже есть");
                    return true; // Existing product found
                }
            }

            return false; // No existing product found
        } catch (error) {
            console.error("Error checking existing product:", error);
            return false;
        }
    };


    const handleAddProduct = async () => {
        try {

            if (loading) {
                console.log("Загрузка продукта уже в процессе. Пожалуйста, подождите.");
                return;
            }
            if (!category || !selectedDocument) {
                console.error("Please select a category and document.");
                return;
            }

            setLoading(true);
            const isExistingProduct = await checkExistingProduct(inputValues["02მოდელი"]);
            if (isExistingProduct) {
                return; // Cancel the upload if a similar product is found
            }
            // Generate a unique ID for the new document
            const idNumber = Math.floor(100000000 + Math.random() * 900000000);
            const id = idNumber.toString();

            // Create a new document in the specified category
            const productRef = fs.collection(category.slice(1).toLowerCase()).doc(id);
            const productData = {};

            // Add selected fields and input values to the document data
            documents.forEach((document) => {
                const fieldValue = selectedField[document] || inputValues[document];
                if (fieldValue) {
                    productData[document] = fieldValue;
                }
            });


            if (selectedFiles.length > 0) {
                const imgPromises = selectedFilesArray.map(async (file, index) => {
                    const imgPath = `IMG/${id}_${index}.webp`;
                    const imgUploadTask = storage.ref(imgPath).put(file);

                    imgUploadTask.on('state_changed', (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    });

                    await imgUploadTask;
                });
                await Promise.all(imgPromises);
            }

            // Update the document in Firestore with the generated data
            await productRef.set(productData);

            console.log("Product added successfully:", productData);
            setLoading(false);
            alert("ПРОДУКТ ДОБАВЛЕН");
            document.getElementById("Add_btn").style.backgroundColor = "#82cb19";

            setTimeout(function() {
                document.getElementById("Add_btn").style.backgroundColor = "#ffffff";
                setLoading(false);
            }, 2000);


        } catch (error) {
            console.error("Error adding product:", error);
        }finally {
            document.getElementById("Add_btn").style.backgroundColor = "#ffffff";
            setUploadProgress(0);
            setLoading(false); // Reset loading state after upload completes
        }
    };





    const handleInputChange = (document, value) => {
        setInputValues({
            ...inputValues,
            [document]: value,
        });
    };

    const handleDocumentChange = async (document) => {
        try {
            setSelectedDocument(document);
            const docRef = fs.collection(category).doc(document);
            const doc = await docRef.get();

            if (doc.exists) {
                const data = doc.data();
                const fields = Object.keys(data);

                setDocumentFields({
                    ...documentFields,
                    [document]: fields,
                });

                // Add the displayed input to the list if fields are empty
                if (fields.length === 0) {
                    setDisplayedInputs([...displayedInputs, document]);
                }
            }
        } catch (error) {
            console.error("Ошибка при получении полей документа:", error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (category) {
                    const collectionRef = fs.collection(category);
                    const snapshot = await collectionRef.get();
                    const documentsArray = snapshot.docs.map(doc => doc.id);
                    setDocuments(documentsArray);
                }
            } catch (error) {
                console.error("Ошибка при получении документов:", error);
            }
        };

        fetchData();
    }, [category]);

    return (
        <div className={"Add"}>

            {loading && (
                <div className={"progress"}>
                    <div className={"progress_content"}>
                        {Math.round(uploadProgress)}%
                    </div>
                </div>
            )}

            <div className="AddProducts">
                <select value={category} onChange={(e) => setCategory(e.target.value)}>
                    <option value={""}>კატეგორია</option>
                    <option value={"Arefrige"}>მაცივარი</option>
                    <option value={"Awashm"}>სარეცხი მანქანა</option>
                    <option value={"Afreezer"}>საყინულე</option>
                    <option value={"Adwash"}>ჭურჭლის სარეცხი მანქანა</option>
                    <option value={"Acond"}>კონდიციონერი</option>
                </select>

                {category && (
                    <div className={"SELECTS"}>
                        {documents.map((document, index) => (
                            <div key={document}>
                                <p onClick={() => handleDocumentChange(document)}>{document}</p>

                                {((documentFields[document] && documentFields[document].length === 0) || displayedInputs.includes(document)) && (

                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Enter field"
                                            value={inputValues[document] || ''}
                                            onChange={(e) => handleInputChange(document, e.target.value)}
                                            key={`input-${index}-${document}`}
                                        />
                                    </div>
                                )}

                                {(documentFields[document] && documentFields[document].length > 0 && !displayedInputs.includes(document)) ? (

                                    <select
                                        value={selectedField[document] || ''}
                                        onChange={(e) => setSelectedField({...selectedField, [document]: e.target.value})}
                                        key={`select-${index}-${document}`}
                                    >
                                        <option value={""}>Select Field</option>
                                        {documentFields[document].map((field) => (
                                            <option key={field} value={field}>
                                                {field}
                                            </option>
                                        ))}
                                    </select>
                                ) : null}
                            </div>
                        ))}
                    </div>
                )}

                <input
                    autoComplete={"off"}
                    type={"file"}
                    id={"file"}
                    className={"IMG_input"}
                    required
                    onChange={(e) => setSelectedFiles(e.target.files)}
                    value={images}
                    multiple={true}
                    accept={imgTypes.join(',')}
                />


                <button id={"Add_btn"} className={"Add_btn"} onClick={handleAddProduct}>
                    Add Product
                </button>
            </div>
        </div>

    );
}

export default AddProducts;