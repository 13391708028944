import React, {useState, useEffect} from 'react';
import '../css/index.css';
import '../css/productPage.css';
import Bar from "../elements/bar";


import Menu from "../elements/menu";
import Footer from "../elements/footer";
import Map from "../elements/map";
import {fs, storage} from "../firebase";
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import i18n from '../i18n';


function ProductPage() {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const {t} = useTranslation();

    const {productId} = useParams();
    const [product, setProduct] = useState({});
    const [img, setImg] = useState([]);
    const [productImageLoaded, setProductImageLoaded] = useState(false);
    const [imgArray, setImgArray] = useState([]);

    const [selectedImageIndex, setSelectedImageIndex] = useState(0);


    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const collectionRef = fs.collection(localStorage.getItem("activeMenu")).doc(productId);
                const snapshot = await collectionRef.get();

                if (snapshot.exists) {
                    const productData = {
                        id: snapshot.id,
                        ...snapshot.data()
                    };
                    setProduct(productData);
                    fetchImagesForProducts(productData);
                } else {
                    // Handle the case when the document does not exist
                }
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };

        const fetchImagesForProducts = async (productData) => {
            try {
                const images = [];
                let index = 0;
                let imageUrl;

                // Keep fetching images until an error occurs
                while (true) {
                    try {
                        imageUrl = await storage.ref(`IMG/${productData.id}_${index}.webp`).getDownloadURL();
                        images.push(imageUrl);
                        index++;
                    } catch (error) {
                        // Break the loop when there are no more images
                        break;
                    }
                }

                setImgArray(images);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };

        fetchData();
    }, [productId]);

    const [existingBasket, setexistingBasket] = useState(JSON.parse(localStorage.getItem("Basket")) || []);


    function addToBasket(productId) {
        // Get the existing basket items from localStorage
        const existingBasket = JSON.parse(localStorage.getItem("Basket")) || [];

        // Check if the productId is already in the basket
        const index = existingBasket.indexOf(productId);

        if (index !== -1) {
            // If productId is found, remove it from the basket
            existingBasket.splice(index, 1);
        } else {
            // If productId is not found, add it to the basket
            existingBasket.push(productId);
        }
        setexistingBasket(JSON.parse(localStorage.getItem("Basket")) || []);
        // Update the localStorage with the updated basket items
        localStorage.setItem("Basket", JSON.stringify(existingBasket));
        console.log(localStorage.getItem("Basket"));


    }

    useEffect(() => {

        addToBasket();

    }, []);


    function SHOWMORE() {
        document.getElementById("show_more").classList.add("active");
        document.getElementById("descript_more").classList.add("active");
    }


    return (
        <div className={`ProductPage ${productImageLoaded ? 'show' : ''}`}>
            <Bar/>

            <div className={"Product_card"}>

                <div className={"product_left_items"}>
                    <div className={"product_card_image"}>
                        <img className={"main-image"} src={imgArray[selectedImageIndex]} />
                        <div className={"product_card_image_container"}>
                            {imgArray.map((imageUrl, index) => (
                                <img
                                    key={index}
                                    className={`thumbnail ${selectedImageIndex === index ? 'selected' : ''}`}
                                    src={imageUrl}
                                    onClick={() => setSelectedImageIndex(index)}

                                />
                            ))}
                        </div>
                    </div>
                    <div className={"product_price_container"}>
                        <div className={"product_card_price"}>
                            {product['ფასი']}
                            <div className={"lari"}>ლ</div>
                            <div className={"product_card_pricew"}>{product["ძველი ფასი"]}
                                <div className={"lari"}>ლ</div>
                            </div>
                        </div>

                        <div className={"product_card_icons"}>

                            <div className={"icon"}>
                                <svg xmlns="http://www.w3.org/2000/svg" className=""
                                     width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                     fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path
                                        d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"/>
                                    <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"/>
                                </svg>
                                ყიდვა
                            </div>
                            <div className={`icon ${existingBasket.includes(product.id) ? 'active' : ''}`}
                                 onClick={() => addToBasket(product.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="" width="24" height="24"
                                     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                     strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"/>
                                    <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"/>
                                    <path d="M17 17h-11v-14h-2"/>
                                    <path d="M6 5l14 1l-1 7h-13"/>
                                </svg>
                                <div className={"icon_basket_text"}>კალათაში</div>
                            </div>
                            <div className={"icons"}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="" width="24" height="24"
                                     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                     strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M18 21v-14"/>
                                    <path d="M9 15l3 -3l3 3"/>
                                    <path d="M15 10l3 -3l3 3"/>
                                    <path d="M3 21l18 0"/>
                                    <path d="M12 21l0 -9"/>
                                    <path d="M3 6l3 -3l3 3"/>
                                    <path d="M6 21v-18"/>
                                </svg>
                            </div>
                        </div>

                    </div>


                </div>


                <div className={"product_card_text"}>
                    <div className={"product_card_name"}>
                        <div className={"product_card_brand"}>
                            {product['01ბრენდი']} -
                        </div>
                        {product['02მოდელი']}
                    </div>

                    <div className={"product_card_id"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className=""
                             width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"/>
                            <path d="M7 17l0 .01"/>
                            <path d="M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"/>
                            <path d="M7 7l0 .01"/>
                            <path d="M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"/>
                            <path d="M17 7l0 .01"/>
                            <path d="M14 14l3 0"/>
                            <path d="M20 14l0 .01"/>
                            <path d="M14 14l0 3"/>
                            <path d="M14 20l3 0"/>
                            <path d="M17 17l3 0"/>
                            <path d="M20 17l0 3"/>
                        </svg>
                        {product.id}
                    </div>

                    <div className={"product_card_description"}>

                        <div className={"product_card_description_container"} id={"descript_more"}>


                            {Object.entries(product)
                                .filter(([key]) => !['id', '01ბრენდი', '02მოდელი', 'კატეგორია', 'ფასი', 'ძველი ფასი', 'სიგანე', 'სიმაღლე', 'სიღრმე'].includes(key))
                                .sort(([key1], [key2]) => key1.localeCompare(key2))
                                .map(([key, value]) => (
                                    <div className={"product_description"} key={key}>
                                        {t(key.slice(2))}: <strong>{t(value)}</strong>
                                    </div>
                                ))}
                            <div className={"product_sizes"}>
                                <div className={"product_sizes_item"}><div className={"product_sizes_text"}>სიმაღლე:</div>  {product.სიმაღლე}</div>
                                <div className={"product_sizes_item"}><div className={"product_sizes_text"}>სიგანე:</div>  {product.სიგანე}</div>
                                <div className={"product_sizes_item"}><div className={"product_sizes_text"}>სიღრმე:</div>  {product.სიღრმე}</div>
                            </div>
                            <div className={"show_more"} id={"show_more"}>
                                <button onClick={SHOWMORE}>
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                         className="" width="24"
                                         height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                         fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M17 13v-6l-5 4l-5 -4v6l5 4z"/>
                                    </svg>
                                    მეტის ნახვა
                                </button>
                            </div>

                        </div>


                    </div>


                </div>


            </div>

            <Footer/>
            <Map/>
        </div>
    );
}

export default ProductPage;
