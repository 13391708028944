import '../css/index.css';
import '../css/about.css';
import Bar from "../elements/bar";
import Footer from "../elements/footer";
import Map from "../elements/map";
import React from "react";


function Cookie() {
    return (
        <div className="App">

            <Bar/>

            <div className={"About"}>
                <div className={"about_text"}>
                    <h1>"ქუქი" პოლიტიკა</h1>
                    <h3>


                        Cookies - იგივე მზა ჩანაწერები, არის მცირე ზომის ტექსტური ფაილები, რომლებიც ეხმარებიან ვებგვერდს შეუფერხებელ და ეფექტურ ფუნქციონირებაში. ისინი ინახება თქვენსავე მოწყობილობაში და სურვილისამებრ შესაძლებელია მათი წაშლა;

                        <br/><br/>

                        აუცილებელი Cookie ფაილები საჭიროა ვებგვერდის საბაზისო ფუნქციების მუშაობისათვის, გვერდზე ნავიგაციისა და უსაფრთხო მოხმარებისათვის;
                        ფუნქციური Cookie ფაილების მთავარი დანიშნულება არის მომხმარებლის მიერ, ვებგვერდზე გაკეთებული ამა თუ იმ არჩევანის დამახსოვრება, რათა მას ყოველ ვიზიტზე ხელახლა არ მოუხდეს ამ არჩევანის გაკეთება, მაგალითად ვებგვერდის მოხმარების წესებსა და Cookie პოლიტიკაზე თანხმობა;
                        ანალიტიკური ტიპის Cookie ფაილები აგროვებენ ინფორმაციას ვებგვერდისა და მისი მომხმარებლის ქცევის შესახებ. იმის მიხედვით თუ ვებგვერდის რომელ ნაწილთან და სეგმენტთან აქვს მომხმარებელს ესა თუ ის ინტერაქცია;

                        <br/><br/>

                        Cookie ფაილების წაშლა ხდება, თქვენს მოწყობილობაში არსებული ვებ-ბრაუზერის პარამეტრებიდან. Cookie ფაილების წაშლის შემდგომ ვებ-გვერდი ხელახლა მოითხოვს თანხმობას Cookie პოლიტიკასა და გამოყენების წესებზე თანხმობას;


                    </h3>

                </div>

            </div>
            <Footer/>
            <Map/>


        </div>
    );
}

export default Cookie;
