import React, { useState } from 'react';
import '../css/index.css';
import '../css/user.css';
import Bar from '../elements/bar';
import Footer from '../elements/footer';
import Map from '../elements/map';
import { fs } from '../firebase.js'; // Adjust the path accordingly
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
function Products() {


    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordLengthError, setPasswordLengthError] = useState(6);
    const [numberError, setNumberError] = useState(9);


    function SWITCH() {
        document.getElementById('login').classList.toggle('active');
        document.getElementById('registration').classList.toggle('active');
    }

    const handleLogin = () => {
        // Code for login, you can use email and password states
    };

    const handleRegistration = () => {
        // Check if the password is less than 6 characters
        if (password.length < 6) {
            setPasswordLengthError(true);
            return;
        }

        if (!password || !confirmPassword) {
            return;
        }

        // Check if the password and confirmPassword match
        if (password !== confirmPassword) {
            setPasswordMatch(false);
            return;
        }

        if (phoneNumber.length < 9) {
            // Handle case where phoneNumber length is less than or equal to 9
            setNumberError(false);
            return;
        }
        // Code for registration, you can use name, phoneNumber, and password states

        // Create a document in the 'Users' collection with the user's phone number as the document ID
        fs.collection('Users').doc(phoneNumber).set({
            name: name,
            number: phoneNumber,
            password: password,
        })
            .then(() => {
                console.log('Document successfully written!');
                document.getElementById("yes_icon").classList.add("active");
                document.getElementById('login').classList.toggle('active');
                document.getElementById('registration').classList.toggle('active');
            })
            .catch((error) => {

            });
    };
    return (
        <div className="User">
            <Bar />
            <div className={'User_container'}>
                <div className="container">
                    <div className={"YES_icon"} id={"yes_icon"}>
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="icon" width="24" height="24"
                             viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M12.01 2.011a3.2 3.2 0 0 1 2.113 .797l.154 .145l.698 .698a1.2 1.2 0 0 0 .71 .341l.135 .008h1a3.2 3.2 0 0 1 3.195 3.018l.005 .182v1c0 .27 .092 .533 .258 .743l.09 .1l.697 .698a3.2 3.2 0 0 1 .147 4.382l-.145 .154l-.698 .698a1.2 1.2 0 0 0 -.341 .71l-.008 .135v1a3.2 3.2 0 0 1 -3.018 3.195l-.182 .005h-1a1.2 1.2 0 0 0 -.743 .258l-.1 .09l-.698 .697a3.2 3.2 0 0 1 -4.382 .147l-.154 -.145l-.698 -.698a1.2 1.2 0 0 0 -.71 -.341l-.135 -.008h-1a3.2 3.2 0 0 1 -3.195 -3.018l-.005 -.182v-1a1.2 1.2 0 0 0 -.258 -.743l-.09 -.1l-.697 -.698a3.2 3.2 0 0 1 -.147 -4.382l.145 -.154l.698 -.698a1.2 1.2 0 0 0 .341 -.71l.008 -.135v-1l.005 -.182a3.2 3.2 0 0 1 3.013 -3.013l.182 -.005h1a1.2 1.2 0 0 0 .743 -.258l.1 -.09l.698 -.697a3.2 3.2 0 0 1 2.269 -.944zm3.697 7.282a1 1 0 0 0 -1.414 0l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l2 2l.094 .083a1 1 0 0 0 1.32 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"
                                strokeWidth="0" fill="currentColor"/>
                        </svg>
                    </div>
                    <div className={'verify'} id={'login'}>
                        {/* Login form */}
                        <input
                            className={'vi'}
                            placeholder={t('ნომერი')}
                            type={"tel"} maxLength={"9"}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                        <input
                            className={'vi'}
                            placeholder={t('პაროლი')}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button className={'vb3'} onClick={handleLogin}>
                            {t('შესვლა')}
                        </button>
                        <button className={'vb2'} onClick={SWITCH}>
                            {t('რეგისტრაცია')}
                        </button>
                    </div>

                    <div className={'verify active'} id={'registration'}>
                        {/* Registration form */}
                        <input
                            className={`vi`}
                            placeholder={t('სახელი')}
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                        <input
                            className={`vi ${numberError.length < 8 ? 'error-border' : ''}`}
                            placeholder={'555  * * *  * * *'}
                            type={"tel"} maxLength={"9"}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value)
                                setNumberError(e.target.value);
                            }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                        <input
                            className={`vi ${passwordLengthError.length < 6 ? 'error-border' : ''}`}
                            placeholder={t('პაროლი')}
                            type="password"
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setPasswordLengthError(e.target.value);
                            }}
                        />
                        <input
                            className={`vi ${!passwordMatch ? 'error-border' : ''}`}
                            placeholder={t('გაიმეორეთ პაროლი')}
                            type="password"
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                                setPasswordMatch(password === e.target.value);
                            }}
                        />
                        <button className={'vb3'} onClick={handleRegistration}>
                            {t('რეგისტრაცია')}
                        </button>
                        <button className={'vb2'} onClick={SWITCH}>
                            {t('შესვლა')}
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
            <Map />
        </div>
    );
}

export default Products;