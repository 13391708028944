import '../css/index.css';
import '../css/about.css';
import Bar from "../elements/bar";
import Footer from "../elements/footer";
import Map from "../elements/map";
import React from "react";


function Services() {



    return (
        <div className="App">

            <Bar/>

            <div className={"Services"}>
                <div className={"Services_container"}>
                    <div className={"Services_card"}>
                        <div className={"Services_img si1"}></div>
                        <div className={"Services_description"}>
                            <div className={"Title"}>
                                Монтаж котла отопления
                            </div>
                            <div className={"Stitle"}>
                                Эффективный монтаж котла отопления - залог вашего комфорта и безопасности. Наши
                                специалисты обеспечат профессиональную установку котла, гарантируя надежную работу
                                системы отопления. Мы заботимся о каждой детали, чтобы ваш дом всегда был теплым и
                                уютным. Доверьте свое отопление опытным профессионалам и наслаждайтесь комфортом без
                                лишних хлопот.
                            </div>
                            <div className={"PTitle"}>
                                <div className="product_price">150
                                    <div className="ico">ლ</div>
                                    <div className="old_price">300
                                        <div className="ico">ლ</div>
                                    </div>
                                </div>
                                <div className="Pbutton">Заказать услугу</div>
                            </div>

                        </div>
                    </div>
                    <div className={"Services_card"}>
                        <div className={"Services_img si2"}></div>
                        <div className={"Services_description"}>
                            <div className={"Title"}>
                                Демонтаж кондиционера
                            </div>
                            <div className={"Stitle"}>
                                Безопасный демонтаж кондиционера - залог вашего комфорта и удовлетворения. Наши специалисты оперативно и бережно удалят кондиционер, следуя строгим стандартам безопасности и экологической ответственности. Доверьтесь опыту наших профессионалов и наслаждайтесь чистым и комфортным воздухом без лишних хлопот.
                            </div>
                            <div className={"PTitle"}>
                                <div className="product_price">120
                                    <div className="ico">ლ</div>
                                    <div className="old_price">230
                                        <div className="ico">ლ</div>
                                    </div>
                                </div>
                                <div className="Pbutton">Заказать услугу</div>
                            </div>

                        </div>
                    </div>
                    <div className={"Services_card"}>
                        <div className={"Services_img si3"}></div>
                        <div className={"Services_description"}>
                            <div className={"Title"}>
                                Монтаж кондиционера
                            </div>
                            <div className={"Stitle"}>
                                Эффективный монтаж кондиционера - ваш комфорт и безопасность в надежных руках. Наши специалисты гарантируют профессиональную установку кондиционера, обеспечивая оптимальное охлаждение помещения. Доверьте свое удобство опытным профессионалам и наслаждайтесь прохладным воздухом без лишних хлопот.
                            </div>
                            <div className={"PTitle"}>
                                <div className="product_price">145
                                    <div className="ico">ლ</div>
                                    <div className="old_price">260
                                        <div className="ico">ლ</div>
                                    </div>
                                </div>
                                <div className="Pbutton">Заказать услугу</div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <Footer/>
            <Map/>


        </div>
    );
}

export default Services;
