import React, {useState, useEffect} from 'react';

import '../css/index.css';
import '../css/menu.css'
import main1 from '../menu/01.png';
import main2 from '../menu/02.png';
import main3 from '../menu/03.png';
import main4 from '../menu/04.png';
import main5 from '../menu/05.png';
import main6 from '../menu/06.png';

import m1 from '../menu/1.png';
import m2 from '../menu/2.png';
import m3 from '../menu/3.png';
import m4 from '../menu/4.png';
import m5 from '../menu/5.png';
import m6 from '../menu/6.png';
import m7 from '../menu/7.png';
import m8 from '../menu/8.png';
import m9 from '../menu/9.png';
import m10 from '../menu/10.png';
import m11 from '../menu/11.png';
import m12 from '../menu/12.png';
import m13 from '../menu/13.png';
import m14 from '../menu/14.png';
import m15 from '../menu/15.png';
import m16 from '../menu/16.png';
import m17 from '../menu/17.png';
import m18 from '../menu/18.png';
import m19 from '../menu/19.png';
import m20 from '../menu/20.png';
import m21 from '../menu/21.png';
import m22 from '../menu/22.png';
import m23 from '../menu/23.png';
import m24 from '../menu/24.png';
import m25 from '../menu/25.png';
import m26 from '../menu/26.png';
import m27 from '../menu/27.png';
import m28 from '../menu/28.png';
import m29 from '../menu/29.png';
import m30 from '../menu/30.png';
import m31 from '../menu/31.png';
import m32 from '../menu/32.png';
import m33 from '../menu/33.png';
import m34 from '../menu/34.png';
import m35 from '../menu/35.png';
import m36 from '../menu/36.png';
import m37 from '../menu/37.png';
import m38 from '../menu/38.png';
import m39 from '../menu/39.png';
import m40 from '../menu/40.png';
import m41 from '../menu/41.png';

import {Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

function Menu() {

    const { t } = useTranslation();

    useEffect(() => {
        const selectedLanguage = localStorage.getItem('selectedLanguage');
        i18n.changeLanguage(selectedLanguage); // or your default language
    }, []);

    function Active(number) {
        for (let i = 1; i <= 6; i++) {
            const menuElement = document.getElementById(`men${i}`);
            if (menuElement && i === number) {
                menuElement.classList.toggle('active');
            }
        }
    }


    const [activeMenu, setActiveMenu] = useState(null);

    const handleMenuClick = (selectedItem) => {
        setActiveMenu(selectedItem.id);
        localStorage.setItem('activeMenu', selectedItem.alt.toString());
        console.log(localStorage.getItem('activeMenu'));
    };



    const menu1 = [
        {id: 1, to: "/products", imgSrc: m41, text: t('ნოუთბუქი'),alt:"qwe"},
        {id: 2, to: "/products", imgSrc: m27, text: t("სმარტფონი"),alt:"qwe"},
        {id: 3, to: "/products", imgSrc: m25, text: t("ტელევიზორი"),alt:"qwe"},
        {id: 4, to: "/products", imgSrc: m28, text: t("მონიტორი"),alt:"qwe"},
        {id: 5, to: "/products", imgSrc: m26, text: t("ტელევიზორის საკიდი"),alt:"qwe"},
        {id: 6, to: "/products", imgSrc: m29, text: t("სისტემური ბლოკი"),alt:"qwe"},
    ];
    const menu2 = [
        {id: 1, to: "/products", imgSrc: m1, text: t("მაცივარი"),alt:"refrige"},
        {id: 2, to: "/products", imgSrc: m2, text: t("საყინულე"),alt:"freezer"},
        {id: 3, to: "/products", imgSrc: m3, text: t("ჭურჭლის სარეცხი"),alt:"dwash"},
        {id: 4, to: "/products", imgSrc: m4, text: t("გასქურა"),alt:"qwe"},
        {id: 5, to: "/products", imgSrc: m5, text: t("ჩასაშენებელი ღუმელი"),alt:"qwe"},
        {id: 6, to: "/products", imgSrc: m18, text: t("სარეცხი მანქანა"),alt:"washm"},
        {id: 7, to: "/products", imgSrc: m19, text: t("საშრობი"),alt:"qwe"},
        {id: 8, to: "/products", imgSrc: m6, text: t("პურის საცხობი"),alt:"qwe"},
        {id: 9, to: "/products", imgSrc: m7, text: t("მიკროტალღური ღუმელი"),alt:"qwe"},
        {id: 11, to: "/products", imgSrc: m6, text: t("პურის საცხობი"),alt:"qwe"},
        {id: 13, to: "/products", imgSrc: m8, text: t("ორთქლის ქვაბი"),alt:"qwe"},
        {id: 14, to: "/products", imgSrc: m11, text: t("მულტსახარში"),alt:"qwe"},
        {id: 15, to: "/products", imgSrc: m9, text: t("ყავის მადუღარა"),alt:"qwe"},
        {id: 16, to: "/products", imgSrc: m10, text: t("წვენსაწური"),alt:"qwe"},
        {id: 17, to: "/products", imgSrc: m24, text: t("ბლენდერი"),alt:"qwe"},
        {id: 18, to: "/products", imgSrc: m12, text: t("სლაისერი"),alt:"qwe"},
        {id: 19, to: "/products", imgSrc: m13, text: t("ხორცსაკეპი"),alt:"qwe"},
        {id: 20, to: "/products", imgSrc: m14, text: t("სენდვიჩმეიკერი"),alt:"qwe"},
        {id: 21, to: "/products", imgSrc: m15, text: t("ბლინების აპარატი"),alt:"qwe"},
        {id: 22, to: "/products", imgSrc: m16, text: t("მიქსერი"),alt:"qwe"},
    ];

    const menu4 = [
        {id: 1, to: "/products", imgSrc: m33, text: t("კონდიციონერი"),alt:"cond"},
        {id: 2, to: "/products", imgSrc: m34, text: t("გათბობის ქვაბი"),alt:"qwe"},
        {id: 3, to: "/products", imgSrc: m35, text: t("გაზის გამათბობელი"),alt:"qwe"},
        {id: 4, to: "/products", imgSrc: m36, text: t("ბუხარი"),alt:"qwe"},
        {id: 5, to: "/products", imgSrc: m37, text: t("ელექტრო გამათბობელი"),alt:"qwe"},
        // Add more items as needed
    ];
    const menu5 = [
        {id: 1, to: "/products", imgSrc: m38, text: t("კომერციული მაცივარი"),alt:"qwe"},
        {id: 2, to: "/products", imgSrc: m39, text: t("დისპენსერი"),alt:"qwe"},
        // Add more items as needed
    ];
    const menu6 = [
        {id: 1, to: "/products", imgSrc: m40, text: t("საკერავი მანქანა"),alt:"qwe"},
        // Add more items as needed
    ];




    return (

        <div className="Menu">

            <div className={"Search_bar"}>
                <svg xmlns="http://www.w3.org/2000/svg" className="searchico" id="searchico" width="24" height="24"
                     viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeWinecap="round"
                     strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                </svg>
                <input type="text" className="search" id="searchname" placeholder={t('ძიება...')}></input>
            </div>


            <div className={"Main_menu"}>
                <div className={"Main_menu_item"} id={"men1"} onClick={() => Active(1)}>
                    <img src={main1} className={"Main_menu_img"}></img>
                    <div className={"Main_menu_text"}>
                       {t('ციფრული ტექნიკა')}
                        <div className={"line"}>
                            <div className={"icon"}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="" width="24" height="24"
                                     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                     strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path
                                        d="M19 2a3 3 0 0 1 3 3v14a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3 -3v-14a3 3 0 0 1 3 -3zm-7 5a1 1 0 0 0 -1 1v5.585l-2.293 -2.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l4 4l.094 .083l.092 .064l.098 .052l.11 .044l.112 .03l.126 .017l.075 .003l.117 -.007l.149 -.029l.105 -.035l.113 -.054l.111 -.071a.939 .939 0 0 0 .112 -.097l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32l-.094 -.083a1 1 0 0 0 -1.32 .083l-2.293 2.292v-5.585l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                                        strokeWidth="0" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className={"Main_menu_description"}>
                            {t('ნოუთბუქები')}, {t('სმარტფონები')}, {t('ტელევიზორი')}, {t('მონიტორი')} ...
                        </div>

                    </div>

                </div>
                <div className={"Menu_container"} id={"m1"}>
                    {menu1.map(item => (
                       <div className={"Menu_img" + (item.alt === "qwe" ? " qwe_style" : "")}>
                           <Link key={item.id} to={item.to} className="Menu_item">
                               <img className={"Menu_img"} src={item.imgSrc} alt={item.text}/>
                               <div className={"Menu_item_text"}>{item.text}</div>
                           </Link>
                       </div>

                    ))}
                </div>
                <div className={"Main_menu_item"} id={"men2"} onClick={() => Active(2)}>
                    <img src={main2} className={"Main_menu_img"}></img>
                    <div className={"Main_menu_text"}>
                        {t('საყოფაცხოვრებო ტექნიკა')}
                        <div className={"line"}>
                            <div className={"icon"}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="" width="24" height="24"
                                     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                     strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path
                                        d="M19 2a3 3 0 0 1 3 3v14a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3 -3v-14a3 3 0 0 1 3 -3zm-7 5a1 1 0 0 0 -1 1v5.585l-2.293 -2.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l4 4l.094 .083l.092 .064l.098 .052l.11 .044l.112 .03l.126 .017l.075 .003l.117 -.007l.149 -.029l.105 -.035l.113 -.054l.111 -.071a.939 .939 0 0 0 .112 -.097l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32l-.094 -.083a1 1 0 0 0 -1.32 .083l-2.293 2.292v-5.585l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                                        strokeWidth="0" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className={"Main_menu_description"}>
                            {t('მაცივარი')}, {t('სარეცხი მანქანა')}, {t('მტვერსასრუტი')}, {t('გასქურა')}, {t('უთო')} ...
                        </div>
                    </div>

                </div>
                <div className={"Menu_container"} id={"m3"}>
                    {menu2.map(item => (
                        <div className={"Menu_img" + (item.alt === "qwe" ? " qwe_style" : "")}>
                        <Link key={item.id} to={item.to} className="Menu_item" onClick={() => { Active(item.id); handleMenuClick(item); }}>
                            <img className={"Menu_img"} src={item.imgSrc} alt={item.text}/>
                            <div className={"Menu_item_text"}>{item.text}</div>
                        </Link>
                        </div>
                    ))}
                </div>
                <div className={"Main_menu_item"} id={"men4"} onClick={() => Active(4)}>
                    <img src={main3} className={"Main_menu_img"}></img>
                    <div className={"Main_menu_text"}>
                        {t('გათბობა-გაგრილება')}
                        <div className={"line"}>
                            <div className={"icon"}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="" width="24" height="24"
                                     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                     strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path
                                        d="M19 2a3 3 0 0 1 3 3v14a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3 -3v-14a3 3 0 0 1 3 -3zm-7 5a1 1 0 0 0 -1 1v5.585l-2.293 -2.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l4 4l.094 .083l.092 .064l.098 .052l.11 .044l.112 .03l.126 .017l.075 .003l.117 -.007l.149 -.029l.105 -.035l.113 -.054l.111 -.071a.939 .939 0 0 0 .112 -.097l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32l-.094 -.083a1 1 0 0 0 -1.32 .083l-2.293 2.292v-5.585l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                                        strokeWidth="0" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className={"Main_menu_description"}>
                            {t('ელექტრო გამათბობელი')}, {t('კარმა')}, {t('გათბობის ქვაბი')}, {t('კონდიციონერი')} ...
                        </div>
                    </div>
                </div>
                <div className={"Menu_container"} id={"m4"}>
                    {menu4.map(item => (
                        <div className={"Menu_img" + (item.alt === "qwe" ? " qwe_style" : "")}>
                        <Link key={item.id} to={item.to} className="Menu_item"  onClick={() => { Active(item.id); handleMenuClick(item); }}>
                            <img className={"Menu_img"} src={item.imgSrc} alt={item.text}/>
                            <div className={"Menu_item_text"}>{item.text}</div>
                        </Link>
                        </div>
                    ))}
                </div>
                <div className={"Main_menu_item"} id={"men5"} onClick={() => Active(5)}>
                    <img src={main5} className={"Main_menu_img"}></img>
                    <div className={"Main_menu_text"}>
                         {t('კომერციული ტექნიკა')}
                        <div className={"line"}>
                            <div className={"icon"}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="" width="24" height="24"
                                     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                     strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path
                                        d="M19 2a3 3 0 0 1 3 3v14a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3 -3v-14a3 3 0 0 1 3 -3zm-7 5a1 1 0 0 0 -1 1v5.585l-2.293 -2.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l4 4l.094 .083l.092 .064l.098 .052l.11 .044l.112 .03l.126 .017l.075 .003l.117 -.007l.149 -.029l.105 -.035l.113 -.054l.111 -.071a.939 .939 0 0 0 .112 -.097l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32l-.094 -.083a1 1 0 0 0 -1.32 .083l-2.293 2.292v-5.585l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                                        strokeWidth="0" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className={"Main_menu_description"}>
                            {t('სარეცხი მანქანა')}, {t('მტვერსასრუტი')}, {t('მაცივარი')}, {t('საყინულე')}, {t('უთო')} ...
                        </div>
                    </div>
                </div>
                <div className={"Menu_container"} id={"m5"}>
                    {menu5.map(item => (
                        <div className={"Menu_img" + (item.alt === "qwe" ? " qwe_style" : "")}>
                        <Link key={item.id} to={item.to} className="Menu_item">
                            <img className={"Menu_img"} src={item.imgSrc} alt={item.text}/>
                            <div className={"Menu_item_text"}>{item.text}</div>
                        </Link>
                        </div>
                    ))}
                </div>
                <div className={"Main_menu_item"} id={"men6"} onClick={() => Active(6)}>
                    <img src={main4} className={"Main_menu_img"}></img>
                    <div className={"Main_menu_text"}>
                        {t('სამკერვალო ნივთები')}
                        <div className={"line"}>
                            <div className={"icon"}>
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="" width="24" height="24"
                                     viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                     strokeLinecap="round"
                                     strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path
                                        d="M19 2a3 3 0 0 1 3 3v14a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3 -3v-14a3 3 0 0 1 3 -3zm-7 5a1 1 0 0 0 -1 1v5.585l-2.293 -2.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l4 4l.094 .083l.092 .064l.098 .052l.11 .044l.112 .03l.126 .017l.075 .003l.117 -.007l.149 -.029l.105 -.035l.113 -.054l.111 -.071a.939 .939 0 0 0 .112 -.097l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32l-.094 -.083a1 1 0 0 0 -1.32 .083l-2.293 2.292v-5.585l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                                        strokeWidth="0" fill="currentColor"/>
                                </svg>
                            </div>
                        </div>
                        <div className={"Main_menu_description"}>
                            {t('საკერავი მანქანა')} ...
                        </div>
                    </div>
                </div>
                <div className={"Menu_container"} id={"m6"}>
                    {menu6.map(item => (
                        <div className={"Menu_img" + (item.alt === "qwe" ? " qwe_style" : "")}>
                        <Link key={item.id} to={item.to} className="Menu_item">
                            <img className={"Menu_img"} src={item.imgSrc} alt={item.text}/>
                            <div className={"Menu_item_text"}>{item.text}</div>
                        </Link>
                        </div>
                    ))}
                </div>
            </div>


        </div>

    );
}

export default Menu;
