import React, { useState, useEffect } from "react";
import '../css/Xbar.css';
import { Link, useLocation } from "react-router-dom";

function XBar() {

    const location = useLocation();
    const [activeItem, setActiveItem] = useState(0); // Инициализируем активный элемент как первый элемент по умолчанию

    // Сопоставляем пути со ссылками в XBar
    const paths = ["/map", "/services", "/", "/contacts", "/id"]; // Пути, соответствующие вашим ссылкам

    useEffect(() => {
        // Ищем путь в списке путей, который соответствует текущему window.location.href
        const index = paths.findIndex(path => path === location.pathname);
        if (index !== -1) {
            setActiveItem(index); // Устанавливаем найденный индекс как активный элемент
        } else {
            setActiveItem(-1); // Если путь не найден, сбрасываем активный элемент
        }
    }, [location.pathname, paths]);

    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    const renderXBarItems = () => {
        const xbarItems = [
            (
               <Link to={"/map"} className={"Linkus"}>
                   <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                        width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                        fill="none" strokeLinecap="round" strokeLinejoin="round">
                       <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                       <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                       <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                   </svg>

               </Link>


            ),
            (
                <Link to={"/services"} className={"Linkus"}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                     height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                     strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5" />
            </svg>
                </Link>
                    ),
            (
                <Link to={"/"} className={"Linkus"}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                     width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                     strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />

                </svg>
                </Link>
            ),

            (

                <Link to={"/contacts"} className={"Linkus"}>
                    <svg  xmlns="http://www.w3.org/2000/svg" className="icon"
                          width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                          fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M8 9h8"/>
                        <path d="M8 13h6"/>
                        <path
                            d="M9 18h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-3l-3 3l-3 -3z"/>
                    </svg>
                </Link>
            ),
            (    <Link to={"/id"} className={"Linkus"}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                    height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                    strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"/>
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"/>
            </svg>
                </Link>
                )
        ];

        return xbarItems.map((item, index) => (
            <div
                key={index}
                className={`xbar_item ${activeItem !== null && index === activeItem ? "active" : ""}`}
                onClick={() => handleItemClick(index)}
            >
                {item}
            </div>
        ));
    };
    return (
        <div className={"xbar"}>
            <div className={"xbar_container"}>
                <div className={"xbar_items"}>
                    {renderXBarItems()}
                </div>
            </div>
        </div>
    );
}

export default XBar;
