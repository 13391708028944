import '../css/footer.css';
import '../css/index.css';

import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';


function Footer() {
    const { t } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage( localStorage.getItem("selectedLanguage")); // or your default language
    }, []);



    return (


        <div>

            <footer className="footer">
                <div className="footer_container">
                    <div className="row">
                        <div className="footer-col">

                            <svg className={"footer_logo"} xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 58.5 26.84">

                                <path className="cls-1"
                                      d="M51.1,16.9c.1-.2.1-.4.2-.6l.6-2.4.6-2.4c.2-.8.4-1.6.6-2.3a23.35,23.35,0,0,1,.7-2.5l.6-2.4a2.92,2.92,0,0,0,.2-.9c.1-.4.2-.4.4-.4h5.7c.3,0,.3.1.4.3V23.2c0,.6,0,.6-.6.6h-3c-.4,0-.4,0-.4-.4V8.8a1.7,1.7,0,0,0-.1-.7c0,.1-.1.1-.1.2a8.33,8.33,0,0,1-.4,1.5c-.2.9-.4,1.7-.6,2.6-.2.7-.3,1.3-.5,2s-.3,1.3-.5,2l-.6,2.4c-.2.8-.4,1.7-.6,2.5s-.3,1.4-.5,2.1v.2c-.1.3-.1.3-.4.3H49.3a.27.27,0,0,1-.3-.3l-.6-2.4c-.2-.7-.3-1.4-.5-2.1s-.4-1.7-.6-2.5c-.2-.6-.3-1.3-.5-1.9s-.3-1.3-.5-2v-.1c-.2-.5-.2-.4.2-.9.8-.8,1.5-1.6,2.3-2.4l.2-.2a.37.37,0,0,0,.1.3l.6,2.4.6,2.4c.2.8.4,1.7.6,2.5l.2.2Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M20.8,3c.1.2,0,.2-.1.3-1.8,1.8-3.5,3.6-5.3,5.4l-2,2-.1.1c-.2.2-.2.2,0,.5l.9,1.5c1.3,2,2.6,3.9,3.9,5.9,1,1.6,2,3.1,3,4.7,0,.1.1.2.1.3a.35.35,0,0,0-.2.1H16.3a.46.46,0,0,1-.5-.3c-.4-.7-.9-1.5-1.3-2.2l-3-5.1c-.4-.6-.7-1.2-1.1-1.9,0-.1-.1-.1-.1-.2l-.2.2c-.9,1-1.9,1.9-2.9,2.9a.91.91,0,0,0-.3.7v5.4c0,.5,0,.5-.5.5H3c-.4,0-.4,0-.4-.4V17a.71.71,0,0,1,.3-.6c.8-.8,1.5-1.6,2.3-2.4,1.2-1.3,2.5-2.6,3.7-3.9,1.9-2,3.7-4.1,5.6-6.1.2-.3.5-.5.7-.8s.3-.2.4-.2h5.2Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M28.1,15.1V6.8c0-.2-.1-.2-.2-.2H22.3c-.3,0-.3-.1-.3-.3V3.4c0-.4.1-.4.4-.4H38c.3,0,.4.1.4.4V6.3c0,.3-.1.3-.3.3H32.6c-.2,0-.2,0-.3.3V21.2a.91.91,0,0,1-.3.7c-.6.6-1.1,1.1-1.6,1.7a.71.71,0,0,1-.6.3H28.4c-.3,0-.3,0-.3-.3V15.1Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M41.1,12.2V3.4c0-.4,0-.5.5-.5H47c.4,0,.4,0,.5.4a7.37,7.37,0,0,0,.4,1.4.62.62,0,0,1-.2.7c-.8.8-1.5,1.6-2.3,2.4-.1.1-.2.1-.3.2s-.1.2-.2.3c-1,1.1-2.1,2.2-3.1,3.3l-.3.3C41.3,12.1,41.2,12.1,41.1,12.2Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M45,13.4V23.5c0,.4,0,.4-.4.4H41.4c-.3,0-.4,0-.4-.4V17.8a.76.76,0,0,1,.2-.5c1-1.1,2-2.1,3-3.2l.4-.4c.1-.2.2-.3.4-.3Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M2.6,7.1V3.2A.52.52,0,0,1,3,3H6.7a.27.27,0,0,1,.3.3V6.6a.91.91,0,0,1-.3.7C5.5,8.5,4.4,9.7,3.2,11c-.1-.1-.2,0-.3.1s-.1.1-.2.1a.35.35,0,0,1-.1-.2V7.1Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M20,27.9a8.3,8.3,0,0,1,1.7,1.5v.1H21a.81.81,0,0,1-.9-.4l-.9-.9A2.54,2.54,0,0,0,18,28a.22.22,0,0,0-.2.2v.9c0,.4,0,.4-.4.4h-.5a.27.27,0,0,1-.3-.3V26.1c0-.3.1-.3.4-.3h2.4c.4,0,.7.1,1.1.1a.68.68,0,0,1,.6.4.91.91,0,0,1-.2,1.4c-.1.1-.3.1-.4.2A.45.45,0,0,0,20,27.9Zm-2.2-1.4v.6c0,.2.1.2.2.2h1.4a.37.37,0,0,0,.3-.1.42.42,0,0,0,.1-.7.37.37,0,0,0-.3-.1H17.9C18,26.4,17.9,26.5,17.8,26.5Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M7,28.2H6.1c-.1,0-.3,0-.3-.1v-.5a.35.35,0,0,1,.2-.1H8a.27.27,0,0,1,.3.3v1.1c0,.2-.1.3-.2.3-.4.1-.7.3-1.1.4a4.61,4.61,0,0,1-2.6-.1,2.84,2.84,0,0,1-1.1-.8,1.65,1.65,0,0,1,.6-2.5,3.26,3.26,0,0,1,1.4-.4,5.92,5.92,0,0,1,1.9.1,1.69,1.69,0,0,1,1,.7c.2.2.1.4-.2.4H7.9a1,1,0,0,1-1.1-.3,1.07,1.07,0,0,0-.6-.2,2,2,0,0,0-1.3.2,1.13,1.13,0,0,0-.7,1.2,1,1,0,0,0,.8,1,2.26,2.26,0,0,0,1.8-.1C7.1,28.6,7.1,28.6,7,28.2Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M32.3,25.7a4.06,4.06,0,0,1,1.9.4,1.78,1.78,0,0,1,0,3.1,3.44,3.44,0,0,1-2.4.4,5.27,5.27,0,0,1-1.2-.3,1.92,1.92,0,0,1-1.1-1.5,2,2,0,0,1,.9-1.7,2.93,2.93,0,0,1,1.6-.4Zm1.6,1.9a1,1,0,0,0-.7-1.1,2.21,2.21,0,0,0-1.6,0,1.13,1.13,0,0,0-.7,1.2,1.23,1.23,0,0,0,.7,1.1,2.29,2.29,0,0,0,1.4.1A1.42,1.42,0,0,0,33.9,27.6Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M46.7,27.1v-1c0-.4,0-.4.4-.4h.5a.27.27,0,0,1,.3.3,20.9,20.9,0,0,1-.1,2.5.88.88,0,0,1-.8.9,4.16,4.16,0,0,1-2.2.2,3.81,3.81,0,0,1-1-.3.89.89,0,0,1-.5-.8c0-.2-.1-.5-.1-.7V26.2c0-.4,0-.5.5-.5h.5c.2,0,.2.1.3.2v2.2c0,.5.1.6.6.8a2.44,2.44,0,0,0,1.2,0,.66.66,0,0,0,.4-.5V27.1Z"
                                      transform="translate(-2.6 -2.9)"/>
                                <path className="cls-1"
                                      d="M56.5,27.6V26.1c0-.3,0-.4.4-.4h2.3a3.59,3.59,0,0,1,1.1.2,1,1,0,0,1,0,1.8,1.61,1.61,0,0,1-.9.3H58.1c-.5,0-.5,0-.5.5v.6c0,.3,0,.3-.3.3h-.6c-.3,0-.3-.1-.3-.3C56.4,28.7,56.5,28.2,56.5,27.6Zm1.8-.2h.8a.55.55,0,0,0,.5-.4c0-.2-.2-.4-.5-.5H57.9c-.1,0-.2,0-.2.2v.6c0,.2.1.2.2.2A.76.76,0,0,1,58.3,27.4Z"
                                      transform="translate(-2.6 -2.9)"/>
                            </svg>


                            <ul>
                                <li><a href="/about"> {t('ჩვენ შესახებ')}</a></li>
                                <li><a href="/services"> {t('სერვისები')}</a></li>
                                <li><a href="/contacts"> {t('კონტაქტი')}</a></li>

                            </ul>
                        </div>
                        <div className="footer-col">
                            <h4> {t('დახმარება')}</h4>
                            <ul>
                                <li><a href="/terms">{t('წესები და პირობები')}</a></li>
                                <li><a href="/policy">{t('პერსონალური მონაცემების პოლიტიკა')}</a></li>
                                <li><a href="/cookie">cookies {t('პოლიტიკა')}</a></li>
                                <li><a href="#">FAQ</a></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h4>{t('ნავიგაცია')}</h4>
                            <ul>
                                <li><a href="#">{t('მაღაზიები')}</a></li>
                                <li><a href="#">{t('გადახდის მეთოდები')}</a></li>
                                <li><a href="#">{t('კომერციული გარანტია')}</a></li>
                                <li><a href="#">{t('ნივთის დაბრუნება')}</a></li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h4>{t('გამოგვყევი')}</h4>
                            <div className="social-links">
                                <a href="#">
                                    <div className={"soc_ico"}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-brand-facebook" width="24"
                                             height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                                d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"></path>
                                        </svg>
                                    </div>

                                </a>
                                <a href="#">
                                    <div className={"soc_ico"}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-brand-instagram" width="24"
                                             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                                d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
                                            <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                            <path d="M16.5 7.5l0 .01"></path>
                                        </svg>
                                    </div>

                                </a>
                                <a href="#">

                                    <div className={"soc_ico"}>
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                             className="icon icon-tabler icon-tabler-brand-vk" width="24" height="24"
                                             viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                             strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path
                                                d="M14 19h-4a8 8 0 0 1 -8 -8v-5h4v5a4 4 0 0 0 4 4h0v-9h4v4.5l.03 0a4.531 4.531 0 0 0 3.97 -4.496h4l-.342 1.711a6.858 6.858 0 0 1 -3.658 4.789h0a5.34 5.34 0 0 1 3.566 4.111l.434 2.389h0h-4a4.531 4.531 0 0 0 -3.97 -4.496v4.5z"></path>
                                        </svg>
                                    </div>

                                </a>

                            </div>
                        </div>

                    </div>

                </div>

            </footer>
        </div>
    );

}

export default Footer;