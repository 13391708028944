import '../css/index.css';
import '../css/bar.css';
import B from '../img/B.png';

function Advert() {

    function CLOSE(){
        document.getElementById("advert").classList.add("active");
    }


    return (
        <div className="advert"  id={"advert"}>
            <div className={"advert_X"} onClick={CLOSE}>
                <svg xmlns="http://www.w3.org/2000/svg" className="advert_X_icon"
                     width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                     strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M10 10l4 4m0 -4l-4 4"/>
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"/>
                </svg>
            </div>
            <img className={"advert_img"} src={B}/>
        </div>
    );
}

export default Advert;