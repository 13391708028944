import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import reportWebVitals from './reportWebVitals';
import Main from "./pages/main";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Products from "./pages/products";
import Userpage from "./pages/userpage";
import AddProducts from "./pages/addproducts";
import './i18n'; // добавьте эту строку
import ProductPage from './pages/productPage';
import About from "./pages/about";
import Cookie from "./pages/cookie";
import Xbar from "./elements/Xbar";

import Map_page from "./pages/map_page";
import Services from "./pages/services";
import Contacts from "./pages/contacts";


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Router>
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/products" element={<Products />} />
                <Route path="/id" element={<Userpage />} />
                <Route path="/:productId" element={<ProductPage  />} />
                <Route path="/add" element={<AddProducts />} />
                <Route path="/about" element={<About />} />
                <Route path="/cookie" element={<Cookie />} />
                <Route path="/map" element={<Map_page />} />
                <Route path="/services" element={<Services />} />
                <Route path="/contacts" element={<Contacts />} />
            </Routes>
            <Xbar/>
        </Router>
    </React.StrictMode>
);

reportWebVitals();