import Bar from "../elements/bar";
import React, {useEffect, useState} from "react";
import '../css/index.css';
import Menu from "../elements/menu";
import Map from "../elements/map";
import Footer from "../elements/footer";



function Main() {
    localStorage.setItem('activeMenu', "PALA");
    return (
        <div className="container">
            <Bar/>
            <Menu/>
            <Footer/>
            <Map/>
        </div>
    );
}

export default Main;
