import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    ge: {
        translation: {


            'doors': 'კარების რაოდენობა',
            'depth': 'სიღრმე',
            'price': 'ფასი',
            'brand': 'ბრენდი',

            'style': 'სტილი',
            'type': 'ტიპი',
            'engine': 'ძრავის ტიპი',
            'noise': 'ხმაურის დონე (Db)',
            'freezerCam': 'საყინულე კამერა (L)',
            'refrigeCam': 'მაცივარი კამერა (L)',
            'system': 'სისტემა',
            'pricew': 'ძველი ფასი',
            'weight': 'წმინდა წონა (Kg)',
            'name': 'სახელი',
            'size': 'ზომები',
            'volume': 'ფართობი',
            'material': 'მასალა',
            'boxWeight': 'შეფუთვის წონა (Kg)',
            'width': 'სიგანე',
            'height': 'სიმაღლე',
            'color': 'ფერი',

        },
    },
    ru: {
        translation: {

             'ჩვენ შესახებ' : 'О нас',
            'დახმარება' : 'Помощь',
            'ნავიგაცია' : 'Навигация',
             'ძიება...' : 'Поиск...',
            'წესები და პირობები' : 'Правила использования',
            'პერსონალური მონაცემების პოლიტიკა' : 'Политика персональных данных',
            'პოლიტიკა' : 'Политика',
            'მაღაზიები' : 'Магазины',
            'გადახდის მეთოდები' : 'Методы оплаты',
            'კომერციული გარანტია' : 'Коммерческая гарантия',
            'ნივთის დაბრუნება' : 'Возврат',

            'მოხმარების პირობები': 'Правила использования',
            'განვადება': 'Рассрочка',
            'ფილიალები': 'Филиалы',
            'გარანტია': 'Гарантия',
            'მთავარი': 'Главная',
            'სერვისები': 'Рассрочка',
            'აქციები': 'Акции',
            'როგორ ვიყიდო': 'Как купить',
            'კონტაქტი': 'Контакты',
            'ციფრული ტექნიკა': 'Цифровая техника',
            'საყოფაცხოვრებო ტექნიკა': 'Бытовая техника',
            'გათბობა-გაგრილება': 'Отопление и вентиляция',
            'კომერციული ტექნიკა': 'Коммерческая техника',
            'სამკერვალო ნივთები': 'Швейные принадлежности',
            'გამოგვყევი': 'Подпишись',

            'შესვლა': 'Вход',
            'რეგისტრაცია': 'Регистрация',

            'სახელი': 'Имя',
            'ნომერი': 'Номер',
            'პაროლი': 'Пароль',
            'გაიმეორეთ პაროლი': 'Повторите пароль',

            'შეტყობინება' : 'Сообщение',
            'ტელევიზორის საკიდი': 'Кронштейн',
            'ნოუთბუქები': 'Ноутбуки',
            'ნოუთბუქი': 'Ноутбуки',
            'სმარტფონები': 'Смартфоны',
            'სმარტფონი': 'Смартфоны',
            'ტელევიზორი': 'Телевизоры',
            'მონიტორი': 'Мониторы',
            'მაცივარი': 'Холодильники',
            'სარეცხი მანქანა': 'Стиральные машины',

            'ჩასაშენებელი ღუმელი': 'Встраиваемая духовка',
            'ჭურჭლის სარეცხი': 'Посудомойка',
            'საშრობი': 'Сушилка',
            'პურის საცხობი': 'Хлебопечка',
            'მიკროტალღური ღუმელი': 'Микроволновка',
            'ყავის მადუღარა': 'Кофе машина',
            'მულტსახარში': 'Мультиварка',
            'წვენსაწური': 'Соковыжималка',
            'სლაისერი': 'Слайсер',
            'ბლენდერი': 'Блендер',
            'ბლინების აპარატი': 'Аппарат для блинов',
            'ხორცსაკეპი': 'Мясорубка',
            'სენდვიჩმეიკერი': 'Сэндвичмейкер',
            'მიქსერი': 'Миксер',
            'ორთქლის ქვაბი': 'Пароварка',


            'სისტემური ბლოკი': 'Cистемный блок',
            'მტვერსასრუტი': 'Пылесосы',
            'გასქურა': 'Кухонные Плиты',
            'უთო': 'Утюги',
            'ელექტრო გამათბობელი': 'Конвекторы',
            'კარმა': 'Карма',
            'გათბობის ქვაბი': 'Отопительные котлы',
            'კონდიციონერი': 'Кондиционеры',
            'საყინულე': 'Морозилки',
            'საკერავი მანქანა': 'Швейные машинки',
            'ბუხარი': 'Камин',
            'გაზის გამათბობელი': 'Газовый котел',
            'კომერციული მაცივარი': 'Коммерческий холодильник',
            'დისპენსერი': 'Коммерческий диспенсер',


            'doors': 'Количество дверей',
            'depth': 'Глубина',
            'price': 'Цена',
            'brand': 'Брэнд',

            'style': 'Стиль',
            'type': 'Тип',
            'engine': 'Тип мотора',
            'noise': 'Уровень шума (Db)',
            'freezerCam': 'Морозильная камера (л)',
            'refrigeCam': 'Холодильная камера (л)',
            'system': 'Система',
            'pricew': 'Старая цена',
            'weight': 'Вес (Kg)',
            'name': 'Название',
            'size': 'Размер',
            'volume': 'Объем',
            'material': 'Материал',
            'boxWeight': 'Вес с упаковкой (Kg)',
            'width': 'Ширина',
            'height': 'Высота',
            'color': 'Цвет',
            'გაგზავნა' : 'Отправить',
             'ცალკე მდგომი' : 'Отдельно стоящий',
            'ერთკარიანი' : 'Онодверный',
            'ინვერტორული' : 'Инверторный',
            'ნახევრად მშრალი' : 'Полусухой',
            'მშრალი' : 'Сухой',
            'რკინა' : 'Железо',
            'შავი' : 'Черный',
            'თეთრი' : 'Белый',

        },
    },
    en: {
        translation: {
            'ჩვენ შესახებ' : 'About Us',
            'დახმარება' : 'Help',
            'ნავიგაცია' : 'Navigation',
            'ძიება...' : 'Search...',
            'წესები და პირობები' : 'Terms of Use',
            'პერსონალური მონაცემების პოლიტიკა' : 'Privacy Policy',
            'პოლიტიკა' : 'Policy',
            'მაღაზიები' : 'Stores',
            'გადახდის მეთოდები' : 'Payment Methods',
            'კომერციული გარანტია' : 'Commercial Guarantee',
            'ნივთის დაბრუნება' : 'Return',

            'გამოგვყევი': 'Follow us',

            'ბუხარი': 'fireplace',
            'შესვლა': 'Login',
            'რეგისტრაცია': 'Registration',

            'სახელი': 'Name',
            'ნომერი': 'Number',
            'პაროლი': 'Password',
            'გაიმეორეთ პაროლი': 'Repeat Password',


            'სისტემური ბლოკი': 'System case',
            'ტელევიზორის საკიდი': 'TV bracket',
            'ჩასაშენებელი ღუმელი': 'Built-in oven',
            'ჭურჭლის სარეცხი': 'Dishwasher',
            'საშრობი': 'Dryer',
            'პურის საცხობი': 'Bread maker',
            'მიკროტალღური ღუმელი': 'Microwave',
            'ყავის მადუღარა': 'Coffee machine',
            'მულტსახარში': 'Multicooker',
            'წვენსაწური': 'Juicer',
            'სლაისერი': 'Slicer',
            'ბლენდერი': 'Blender',
            'ბლინების აპარატი': 'Crepe maker',
            'ხორცსაკეპი': 'Meat grinder',
            'სენდვიჩმეიკერი': 'Sandwich maker',
            'მიქსერი': 'Mixer',
            'ორთქლის ქვაბი': 'Steamer',


            'კომერციული მაცივარი': 'Commercial refrigerator',
            'დისპენსერი': 'Commercial dispenser',
            'მოხმარების პირობები': 'Terms of Use',
            'განვადება': 'Installment Payment',
            'ფილიალები': 'Branches',
            'გარანტია': 'Guarantee',
            'მთავარი': 'Home',
            'სერვისები': 'Installment Payment',
            'აქციები': 'Promotions',
            'როგორ ვიყიდო': 'How to Buy',
            'კონტაქტი': 'Contacts',
            'ციფრული ტექნიკა': 'Digital Technology',
            'საყოფაცხოვრებო ტექნიკა': 'Household Appliances',
            'გათბობა-გაგრილება': 'Heating and Ventilation',
            'კომერციული ტექნიკა': 'Commercial Equipment',
            'სამკერვალო ნივთები': 'Sewing Supplies',
            'გაზის გამათბობელი': 'Gas boiler',
            'ნოუთბუქები': 'Laptops',
            'ნოუთბუქი': 'Laptops',
            'სმარტფონები': 'Smartphones',
            'სმარტფონი': 'Smartphones',
            'ტელევიზორი': 'Televisions',
            'მონიტორი': 'Monitors',
            'მაცივარი': 'Refrigerators',
            'სარეცხი მანქანა': 'Washing Machines',
            'მტვერსასრუტი': 'Vacuum Cleaners',
            'გასქურა': 'Cooktops',
            'უთო': 'Irons',
            'ელექტრო გამათბობელი': 'Convection Heaters',
            'კარმა': 'Karma',
            'გათბობის ქვაბი': 'Heating Boilers',
            'კონდიციონერი': 'Air Conditioners',
            'საყინულე': 'Freezers',
            'საკერავი მანქანა': 'Sewing Machines',

             'გაგზავნა':'Send',
            'doors': 'Number of Doors',
            'depth': 'Depth',
            'price': 'Price',
            'brand': 'Brand',
            'შეტყობინება' : 'Message',
            'style': 'Style',
            'type': 'Type',
            'engine': 'Engine Type',
            'noise': 'Noise Level (Db)',
            'freezerCam': 'Freezer Compartment (L)',
            'refrigeCam': 'Refrigerator Compartment (L)',
            'system': 'System',
            'pricew': 'Old Price',
            'weight': 'Weight (Kg)',
            'name': 'Name',
            'size': 'Size',
            'volume': 'Volume',
            'material': 'Material',
            'boxWeight': 'Weight with Packaging (Kg)',
            'width': 'Width',
            'height': 'Height',
            'color': 'Color',

            'ცალკე მდგომი' : 'Separate',
            'ერთკარიანი' : 'Single door',
            'ინვერტორული' : 'Inverter',
            'ნახევრად მშრალი' : 'Semi-dry',
            'მშრალი' : 'Dry',
            'რკინა' : 'Iron',
            'შავი' : 'Black',
            'თეთრი' : 'White',


        },
    },





};

i18n
    .use(initReactI18next)
    .init({
        lng: 'ru', // язык по умолчанию
        resources,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;