import React, {useState, useEffect} from 'react';
import '../css/index.css';
import '../css/products.css';
import Bar from "../elements/bar";
import Footer from "../elements/footer";
import Map from "../elements/map";
import {fs, storage} from "../firebase";
import {Link} from "react-router-dom";
import W from "../img/w.svg";

function Products() {


    const [loading, setLoading] = useState(true);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(9999);
    const [products, setProducts] = useState([]);
    const [minWidth, setMinWidth] = useState('');
    const [maxWidth, setMaxWidth] = useState('');
    const [minHeight, setMinHeight] = useState('');
    const [maxHeight, setMaxHeight] = useState('');
    const [minDepth, setMinDepth] = useState('');
    const [maxDepth, setMaxDepth] = useState('');
    const [productImages, setProductImages] = useState({});
    const [documents, setDocuments] = useState([]);
    const [documentFields, setDocumentFields] = useState({});
    const [selectedFields, setSelectedFields] = useState({});
    const [selectedDocument, setSelectedDocument] = useState("");
    const [selectedField, setSelectedField] = useState("");
    const [selectChanged, setSelectChanged] = useState(false);
    const [search, setSearch] = useState("");


    function SHOWFILTER(){
        document.getElementById("filter").classList.toggle("active");
    }
    const handleMinInputChange = (e) => {
        const newMinValue = parseInt(e.target.value, 10);
        setMinValue(newMinValue);
    };
    const handleMaxInputChange = (e) => {
        const newMaxValue = parseInt(e.target.value, 10);
        setMaxValue(newMaxValue);
        if (newMaxValue <= 0) {
            setMaxValue(9999);
        }
    };
    const handleRangeMinChange = (e) => {
        setMinValue(parseInt(e.target.value, 10));
    };
    const handleRangeMaxChange = (e) => {
        setMaxValue(parseInt(e.target.value, 10));
    };







    useEffect(() => {
        preloadImages();
        const fetchImagesForProducts = async () => {
            setLoading(true);
            try {
                const imagePromises = products.map(async (product) => {
                    try {
                        const imageUrl = await storage.ref(`IMG/${product.id}_${0}.webp`).getDownloadURL();
                        await new Promise((resolve) => {
                            const image = new Image();
                            image.onload = () => {
                                resolve();
                            };
                            image.src = imageUrl;
                        });
                        return { productId: product.id, imageUrl };
                    } catch (error) {
                        console.error(`Error fetching image for product ${product.id}:`, error);
                        return null;
                    }
                });

                const imageUrls = await Promise.all(imagePromises);
                const validImages = imageUrls.filter((item) => item !== null);

                const imageObject = validImages.reduce((acc, { productId, imageUrl }) => {
                    acc[productId] = imageUrl;
                    return acc;
                }, {});

                setProductImages(imageObject);
            } catch (error) {
                console.error('Error fetching images:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchImagesForProducts();
    }, [products]);


    useEffect(() => {
        console.log(localStorage.getItem("activeMenu"));
        const fetchData = async () => {
            try {
                const collectionRef = fs.collection(localStorage.getItem("activeMenu"));
                const snapshot = await collectionRef.get();
                const productsData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));


                const filteredProducts = productsData.filter(product => (
                    (!selectChanged || Object.keys(selectedFields).every((document) => (
                        !document || !selectedFields[document].field || product[document] === selectedFields[document].field
                    ))) &&
                    (!minValue || isNaN(product.ფასი) || product.ფასი >= minValue) &&
                    (!maxValue || isNaN(product.ფასი) || product.ფასი <= maxValue) &&
                    (!maxHeight || isNaN(product.სიმაღლე) || product.სიმაღლე >= parseInt(maxHeight, 10) || !maxHeight) &&
                    (!maxWidth || isNaN(product.სიგანე) || product.სიგანე >= parseInt(maxWidth, 10) || !maxWidth) &&
                    (!maxDepth || isNaN(product.სიღრმე) || product.სიღრმე >= parseInt(maxDepth, 10) || !maxDepth) &&
                    (!search || product["02მოდელი"].toUpperCase().includes(search.toUpperCase()))
                ));


                setProducts(filteredProducts);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };
        fetchData()
    }, [minValue, maxValue, maxHeight,  maxWidth, maxDepth, selectChanged, selectedFields, search]);

    const preloadImages = () => {
        products.forEach((product) => {
            const img = new Image();
            img.src = productImages[product.id];
        });
    };


    const handleProductClick = (productId) => {
        localStorage.setItem('selectedProductId', productId);

        // Update both selectedDocument and selectedField
        setSelectedDocument(selectedDocument);
        setSelectedField(selectedField);
    };









    useEffect(() => {
        const fetchDocumentsAndFields = async () => {
            try {
                const documentsSnapshot = await fs.collection("F" + localStorage.getItem("activeMenu")).get();
                const documentsData = documentsSnapshot.docs.map(doc => doc.id);
                setDocuments(documentsData);

                const fieldsPromises = documentsData.map(async document => {
                    const docRef = fs.collection("F" + localStorage.getItem("activeMenu")).doc(document);
                    const doc = await docRef.get();
                    if (doc.exists) {
                        const data = doc.data();
                        const fields = Object.keys(data);
                        return { document, fields };
                    }
                    return { document, fields: [] };
                });

                const fieldsData = await Promise.all(fieldsPromises);
                const fieldsObj = {};
                fieldsData.forEach(({ document, fields }) => {
                    fieldsObj[document] = fields;
                });
                setDocumentFields(fieldsObj);
            } catch (error) {
                console.error("Ошибка при получении документов и полей:", error);
            }
        };

        fetchDocumentsAndFields();
    }, []);
    const handleDocumentChange = (document) => {
        setSelectedFields((prevSelectedFields) => ({
            ...prevSelectedFields,
            [document]: {
                field: "",
            },
        }));
        setSelectChanged(true);
    };
    const handleFieldChange = (document, field) => {
        setSelectedFields((prevSelectedFields) => ({
            ...prevSelectedFields,
            [document]: {
                field,
            },
        }));
        setSelectChanged(true);
    };






    function LoadingIndicator() {
        return <div className={"loader"}><div className={"loader_background"}></div><img src={W}></img> </div>;
    }


    const [visibleProducts, setVisibleProducts] = useState(9); // Изначально отображаем 10 продуктов

    const loadMore = () => {
        setVisibleProducts(prevVisibleProducts => prevVisibleProducts + 9); // Увеличиваем количество отображаемых продуктов на 10
    };


    return (
        <div className="Products">
            <Bar/>
            <div className={"Products_container"}>

                {loading ? (
                    <LoadingIndicator />
                ) : (
              <>
                  <div className={"filter"} id={"filter"}>
                      <h2>ფილტრი</h2>
                      <div className={"remove"}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                               width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                               fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                              <path
                                  d="M20 6a1 1 0 0 1 .117 1.993l-.117 .007h-.081l-.919 11a3 3 0 0 1 -2.824 2.995l-.176 .005h-8c-1.598 0 -2.904 -1.249 -2.992 -2.75l-.005 -.167l-.923 -11.083h-.08a1 1 0 0 1 -.117 -1.993l.117 -.007h16zm-9.489 5.14a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                                  strokeWidth="0" fill="currentColor"/>
                              <path
                                  d="M14 2a2 2 0 0 1 2 2a1 1 0 0 1 -1.993 .117l-.007 -.117h-4l-.007 .117a1 1 0 0 1 -1.993 -.117a2 2 0 0 1 1.85 -1.995l.15 -.005h4z"
                                  strokeWidth="0" fill="currentColor"/>
                          </svg>
                          გასუფთავება
                      </div>
                      {/*ЦЕНА*/}
                      <div>
                          <div className="price-input">
                              <div className="field">
                                  <span>Min</span>
                                  <input
                                      maxLength={maxValue}
                                      type="number"
                                      className="input-min"
                                      value={minValue}
                                      onChange={handleMinInputChange}
                                  />
                              </div>

                              <div className="field">
                                  <span>Max</span>
                                  <input
                                      minLength={minValue}
                                      type="number"
                                      className="input-max"
                                      value={maxValue}
                                      onChange={handleMaxInputChange}
                                  />
                              </div>
                          </div>
                          <div className="slider">
                              <div className="progress"
                                   style={{
                                       width: `${maxValue / 100 - minValue / 100}%`,
                                       left: `${minValue / 100}%`
                                   }}></div>
                          </div>
                          <div className="range-input">
                              <input
                                  maxLength={maxValue}

                                  type="range"
                                  className="range-min"
                                  min="0"
                                  max="10000"
                                  value={minValue}
                                  step="100"
                                  onChange={handleRangeMinChange}
                              />
                              <input
                                  minLength={minValue}
                                  type="range"
                                  className="range-max"
                                  min="0"
                                  max="10000"
                                  value={maxValue}
                                  step="100"
                                  onChange={handleRangeMaxChange}
                              />
                          </div>
                      </div>

                      <div className={"brands"}>
                          {documents.map((document) => (
                              <div key={document}>
                                  <div
                                      className={"brands_id"}>

                                      {documentFields[document] && (
                                          <select
                                              value={selectedFields[document]?.field || ""}
                                              onChange={(e) => handleFieldChange(document, e.target.value)}
                                          >
                                              <option value="">{document.slice(2)}</option>
                                              {documentFields[document].map((field) => (
                                                  <option key={field} value={field}>
                                                      {field}
                                                  </option>
                                              ))}
                                          </select>
                                      )}
                                      <svg xmlns="http://www.w3.org/2000/svg"
                                           className="brand_ico" width="24"
                                           height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                           fill="none" strokeLinecap="round" strokeLinejoin="round">
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                          <path
                                              d="M19 2a3 3 0 0 1 3 3v14a3 3 0 0 1 -3 3h-14a3 3 0 0 1 -3 -3v-14a3 3 0 0 1 3 -3zm-7 5a1 1 0 0 0 -1 1v5.585l-2.293 -2.292l-.094 -.083a1 1 0 0 0 -1.32 1.497l4 4l.094 .083l.092 .064l.098 .052l.11 .044l.112 .03l.126 .017l.075 .003l.117 -.007l.149 -.029l.105 -.035l.113 -.054l.111 -.071a.939 .939 0 0 0 .112 -.097l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32l-.094 -.083a1 1 0 0 0 -1.32 .083l-2.293 2.292v-5.585l-.007 -.117a1 1 0 0 0 -.993 -.883z"
                                              strokeWidth="0" fill="currentColor"/>
                                      </svg>

                                  </div>


                              </div>
                          ))}
                      </div>

                      {/*РАЗМЕРЫ*/}
                      <div className={"sizes"}>
                          <div className={"sizes_continaer"}>
                              <div className={"sizes"}>
                                  <div className={"sizes_continaer"}>
                                      <div className={"sizes_text"}>
                                          სიმაღლე (მმ)
                                      </div>
                                      <div className={"size"}>
                                          <input
                                              type="number"
                                              className="size_input"
                                              value={maxHeight}
                                              onChange={({ target }) => setMaxHeight(target.value)}
                                              placeholder="მაქ. სიმაღლე"
                                          />
                                      </div>
                                      <div className={"sizes_text"}>
                                          სიგანე (მმ)
                                      </div>
                                      <div className={"size"}>
                                          <input
                                              type="number"
                                              className="size_input"
                                              value={maxWidth}
                                              onChange={({ target }) => setMaxWidth(target.value)}
                                              placeholder="მაქ. სიგანე"
                                          />
                                      </div>
                                      <div className={"sizes_text"}>
                                          სიღრმე (მმ)
                                      </div>
                                      <div className={"size"}>
                                          <input
                                              type="number"
                                              className="size_input"
                                              value={maxDepth}
                                              onChange={({ target }) => setMaxDepth(target.value)}
                                              placeholder="მაქ. სიღრმე"
                                          />
                                      </div>
                                  </div>
                              </div>

                          </div>
                      </div>

                      {/*КНОПКА ФИЛТРАЦИИ*/}
                      <div className={"filter_btn"} onClick={SHOWFILTER}>
                          <button>
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   className="" width="24" height="24"
                                   viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none"
                                   strokeLinecap="round" strokeLinejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                  <path
                                      d="M5 5m0 .5a.5 .5 0 0 1 .5 -.5h4a.5 .5 0 0 1 .5 .5v4a.5 .5 0 0 1 -.5 .5h-4a.5 .5 0 0 1 -.5 -.5z"/>
                                  <path
                                      d="M5 14m0 .5a.5 .5 0 0 1 .5 -.5h4a.5 .5 0 0 1 .5 .5v4a.5 .5 0 0 1 -.5 .5h-4a.5 .5 0 0 1 -.5 -.5z"/>
                                  <path className={"arrow"} d="M14 15l3 3l3 -3"/>
                                  <path className={"arrow"} d="M17 18v-12"/>
                              </svg>ფილტრი


                          </button>
                      </div>

                  </div>

                  <div className={"products_list"}>
                      <div className={"Search_barP"}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="searchicoP" id="searchico" width="24" height="24"
                               viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                               strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                              <path d="M21 21l-6 -6"></path>
                          </svg>
                          <input type="text" className="searchP" id="searchname" placeholder="ძიება..."  onChange={({ target }) => setSearch(target.value)}></input>
                      </div>
                      {products.slice(0, visibleProducts).map((product, index) => (
                          productImages[product.id] && (
                              <Link
                                  to={"/" + product.id}
                                  key={product.id}
                                  className={`product_itm ${productImages[product.id] ? 'show' : ''}`}

                                  onClick={() => handleProductClick(product.id)}
                              >
                                  <div className="product_itm_container">


                                      <div className="product_img">
                                          <img src={productImages[product.id]} />
                                      </div>

                                      <div className={"product_text"}>

                                          <div className={"product_name"}>{product['02მოდელი']}</div>
                                          <div className={"product_brand"}><div className={"product_brand_model"}>{product['01ბრენდი']}</div><div className={"product_brand_id"}>  id: {product.id}</div> </div>



                                          <div className={"product_price"}>
                                              {product.ფასი}
                                              <div className={"ico"}>ლ</div>
                                              <div className={"old_price"}>
                                                  {product["ძველი ფასი"]}
                                                  <div className={"ico"}>ლ</div>
                                              </div>
                                          </div>



                                      </div>
                                  </div>
                              </Link>
                          )
                      ))}
                      {visibleProducts < products.length && (
                          <div className={"load_more"}>
                              <button  onClick={loadMore}>მეტის ნახვა</button>
                          </div>
                      )}


                  </div>
              </>
                )}


            </div>
            <Footer/>
            <Map/>
        </div>
    );
}

export default Products;
