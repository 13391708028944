import '../css/index.css';
import '../css/bar.css';
import '../css/burger.css';
import LOGO from '../img/logo.svg';
import Advert from "./advert";
import React, {useState, useEffect} from 'react';
import Ge from "../img/ge.svg"
import Ru from "../img/ru.svg"
import En from "../img/en.svg"
import {Link} from 'react-router-dom';


import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; // подключите ваш i18n

function Bar() {
    const [selectedLanguage, setSelectedLanguage] = useState(
        localStorage.getItem("selectedLanguage") || "ge"
    );

    const { t } = useTranslation();

    const handleLanguageChange = (event) => {
        const selectedLang = event.target.value;

        setSelectedLanguage(selectedLang);
        localStorage.setItem("selectedLanguage", selectedLang);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        handleLanguageChange({ target: { value: lng } });

    };

    useEffect(() => {
        changeLanguage(selectedLanguage);
    }, [selectedLanguage]);


    const [isShown, setIsShown] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const togglePanel = async () => {
        setIsShown(!isShown);
    };



    const toggleMode = () => {
        const root = document.documentElement;

        // Toggle between dark and light mode
        if (isDarkMode) {
            root.style.setProperty("--main-color-1", "#1D1D1B");
            root.style.setProperty("--main-color-2", "#FFFFFF");
            root.style.setProperty("--main-color-3", "#f8f8f8");
            root.style.setProperty("--main-color-4", "#ED1C24");
            root.style.setProperty("--main-color-5", "#ED1C24");
            root.style.setProperty("--main-color-6", "#242422");
        } else {
            root.style.setProperty("--main-color-1", "#FFFFFF");
            root.style.setProperty("--main-color-2", "#1D1D1B");
            root.style.setProperty("--main-color-3", "#242422");
            root.style.setProperty("--main-color-4", "#242422");
            root.style.setProperty("--main-color-5", "#FFFFFF");
            root.style.setProperty("--main-color-6", "#FFFFFF");

        }

        // Toggle the mode state
        setIsDarkMode(!isDarkMode);
    };


    return (
        <div className="Bar">
            <div className={"Bar_container1"}>
                <div className={"bar_item"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="bar_ico" width="24"
                         height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                         strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path
                            d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"/>
                        <path d="M15 7a2 2 0 0 1 2 2"/>
                        <path d="M15 3a6 6 0 0 1 6 6"/>
                    </svg>
                    +995 514 200 990
                </div>
                <div className={"bar_item bar_time"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="bar_ico" width="24"
                         height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                         strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"/>
                        <path d="M3 7l9 6l9 -6"/>
                    </svg>
                    info@ktmgroup.ge
                </div>
                <div className={"bar_item"}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="bar_ico"
                         width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                         strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"/>
                        <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"/>
                        <path d="M15 3v4"/>
                        <path d="M7 3v4"/>
                        <path d="M3 11h16"/>
                        <path d="M18 16.496v1.504l1 1"/>
                    </svg>
                    10:00-19:00
                </div>
                <div className={"bar_item BarL MR"}>
                   {t('მოხმარების პირობები')}
                </div>
                <div className={"bar_item BarL"}>
                    {t('განვადება')}
                </div>
                <div className={"bar_item BarL"}>
                    {t('ფილიალები')}
                </div>
                <div className={"bar_item BarL"}>
                    {t('გარანტია')}
                </div>
            </div>
            <div className={"Bar_container2"}>
             <Link to={"/"}>

                 <img src={LOGO} className={"Logo"}></img>

             </Link>


                <div className={"menu"}>
                    <ul>
                        <li><Link to={"/"}> {t('მთავარი')}</Link></li>
                        <li><Link to={"/services"}>{t('სერვისები')}</Link></li>
                        <li><Link to={"/"}>{t('აქციები')}</Link></li>
                        <li><Link to={"/"}>{t('როგორ ვიყიდო')}</Link></li>
                        <li><Link to={"/contacts"}>{t('კონტაქტი')}</Link></li>

                    </ul>
                </div>
                <div className={"IconsR"}>
                    <Link to={"/id"}>
                    <div className={"user"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24"
                             height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"/>
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"/>
                        </svg>
                    </div>
                    </Link>
                    <div className={"user"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon"
                             width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                             fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <path d="M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"/>
                            <path d="M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"/>
                            <path d="M17 17h-11v-14h-2"/>
                            <path d="M6 5l14 1l-1 7h-13"/>
                        </svg>
                    </div>
                    <div className={"Icon"}>
                        <div className={"swicher"}>
                            <input
                                type="checkbox"
                                className="checkbox"
                                id="checkbox"
                                onClick={toggleMode}
                            />
                            <label htmlFor="checkbox" className="checkbox-label">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="fas fa-moon icon icon-tabler icon-tabler-sun"
                                     width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                     fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"/>
                                    <path
                                        d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"/>
                                </svg>

                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="fas fa-moon icon icon-tabler icon-tabler-moon-stars"
                                     width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                     fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path
                                        d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"/>
                                    <path d="M17 4a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2"/>
                                    <path d="M19 11h2m-1 -1v2"/>
                                </svg>

                                <span className="ball"></span>
                            </label>
                        </div>
                    </div>
                    <div className={"lang"}>
                        <img
                            className={"flag"}
                            src={i18n.language === 'ge' ? Ge : i18n.language === 'ru' ? Ru : En}
                            alt="Flag"
                        />
                        <select
                            className="language-dropdown"
                            value={selectedLanguage}
                            onChange={(e) => changeLanguage(e.target.value)}
                        >
                            <option value="ge">ქა</option>
                            <option value="ru">Ру</option>
                            <option value="en">En</option>
                        </select>
                    </div>


                    <div className={"Icon BURGER_M"}>
                        <div className={"Burger"} onClick={({target}) => target.classList.toggle('active')}>
                            <div className="burgerICO" onClick={togglePanel}>
                                <svg id="line" className="ham hamRotate ham1 null" viewBox="0 0 100 100"
                                     width="57">
                                    <path className="line top path"
                                          d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"></path>
                                    <path className="line middle path" d="m 30,50 h 40"></path>
                                    <path className="line bottom path"
                                          d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    {isShown && (
                        <div className="Panel">
                            <div className={"Panel_continaer"}>
                                <div className={"bar_item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="bar_ico" width="24"
                                         height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path
                                            d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"/>
                                        <path d="M15 7a2 2 0 0 1 2 2"/>
                                        <path d="M15 3a6 6 0 0 1 6 6"/>
                                    </svg>
                                    +995 514 200 990
                                </div>
                                <div className={"bar_item bar_time"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="bar_ico" width="24"
                                         height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"/>
                                        <path d="M3 7l9 6l9 -6"/>
                                    </svg>
                                    info@ktmgroup.ge
                                </div>
                                <div className={"bar_item"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="bar_ico"
                                         width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                         strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                        <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"/>
                                        <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"/>
                                        <path d="M15 3v4"/>
                                        <path d="M7 3v4"/>
                                        <path d="M3 11h16"/>
                                        <path d="M18 16.496v1.504l1 1"/>
                                    </svg>
                                    10:00-19:00
                                </div>
                                <div className={"menu"}>
                                    <ul>
                                        <li><Link to={"/"}> {t('მთავარი')}</Link></li>
                                        <li><Link to={"/"}>{t('სერვისები')}</Link></li>
                                        <li><Link to={"/"}>{t('აქციები')}</Link></li>
                                        <li><Link to={"/"}>{t('როგორ ვიყიდო')}</Link></li>
                                        <li><Link to={"/"}>{t('კონტაქტი')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Advert/>
        </div>
    );
}

export default Bar;
